<template>
  <div>
    <div
      class="menus"
      v-show="menuNames.includes($route.name)"
    >
      <el-menu
        mode="horizontal"
        :default-active="$route.name"
        @select="handleDirect"
      >
        <el-menu-item
          v-for="item in menus"
          :key="item.path"
          :index="item.path"
        >{{item.name}}</el-menu-item>
      </el-menu>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  computed: {
    menuNames () {
      return this.menus.map(v => v.path)
    }
  },
  data () {
    const menus = [
      { path: 'marketing.shop.goods.selling', name: '销售中' },
      { path: 'marketing.shop.goods.soldout', name: '已售罄' },
      { path: 'marketing.shop.goods.instore', name: '仓库中' }
    ]
    return {
      menus
    }
  },
  methods: {
    handleDirect (name) {
      this.$router.push({ name })
    }
  }

}
</script>
<style lang="scss" scoped>
  .menus{
    margin-bottom: $padding-contain;
  }
</style>
